.premios {
  position: relative;
  background: $yellow url('/images/prizes-back.png') no-repeat center top / cover;
  z-index: 3;
  padding-top: 200px;
  margin-top: -100px;

  .pull-right {
    @include mq(md) {
      order: 2;
    }
  }

  &__yellow-footer {
    height: 0;
    width: 100%;
    padding-bottom: 20%;
    background: url('/images/yellow-footer.png') no-repeat top center / cover;
    position: relative;
    z-index: 2;
    margin-top: 10%;
  }



  &__title {
      margin: 0;
      text-align: center;
      color: $red;
      text-transform: uppercase;
      font-family: $titleFont;
      font-weight: 100;
      font-size: 60px;

      @include mq(md) {
          font-size: 200px;
      }
  }

  &__subtitle {
      margin: 0;
      text-align: center;
      text-transform: uppercase;
      color: $white;
      font-weight: 100;
      font-family: $titleFont;
      margin-bottom: 10px;
      font-size: 30px;
      width: 90%;
      margin-left: auto;
      margin-right: auto;

      @include mq(md) {
          width: auto;
          font-size: 50px;
          margin-top: -40px;
          margin-left: 0;
          margin-right: 0;
      }
  }

  &__text {
      margin: 0;
      text-align: center;
      color: $white;
      font-size: 16px;
      margin-bottom: 20px;
      font-family: $titleFont;
      font-weight: 100;

      @include mq(md) {
          margin-bottom: 0;
      }
  }

  &__index {
      width: 100%;
      position: relative;
      height: 0;
      overflow: hidden;
      padding-bottom: 90%; 

      img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate3d(-50%, -50%, 0);
          max-width: 90%;
          max-height: 90%;
      }
  }

  &__table {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 40px;
  }

  &__table-supertitle {
    color: $white;
    font-size: 21px;
    text-align: center;
    margin: 0 0 10px;
    font-weight: 100;
  }

  &__table-title {
      text-transform: uppercase;
      font-size: 32px;
      text-align: center;
      font-family: $titleFont;
      font-weight: 100;
      margin: 0 0 20px;

      &.-white {
          color: $white;
      }

      &.-blue {
          color: $blue;
      }

      &.-orange {
          color: $yellow;
      }

      &.-red {
        color: $red;
      }
  }

  &__table-group {
      display: flex;
      justify-content: space-between;
      height: 60px;

      &:not(:last-child) {
          margin-bottom: 10px;
      }
  }

  &__cell {
      background: $white;
      position: relative;
      border-radius: 5px;

      &.-avatar {
          width: 17%;

          img {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate3d(-50%, -50%, 0);
          }
      }

      &.-name {
          width: 65%;
          padding-left: 20px;
          
          p {
              line-height: 60px;
              font-family: $titleFont;            
              text-transform: uppercase;
              color: $red;
              margin: 0;
              font-size: 18px;

              @include mq(md) {
                  font-size: 20px;
              }
          }
      }

      &.-score {
          width: 15%;
          line-height: 60px;
          color: $red;
          text-align: center;
          font-family: $titleFont;            
          font-weight: 700;
          font-size: 20px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
      }
  }

  &__buttons {
      display: none;

      @include mq(md) {
          display: flex;
          justify-content: center;
      }
  }

  &__button {
      width: 22%;
      text-align: center;
      text-transform: uppercase;
      font-family: $titleFont;            
      text-decoration: none;
      height: 60px;
      line-height: 60px;
      font-size: 24px;
      border: none;
      border-radius: 5px;
      margin: 0 10px;

      &.-red {
          background: $red;
          color: $yellow;
          box-shadow: 0 5px 0 1px ($red - #333);
      }

      &.-yellow {
          background: $yellow;
          color: $red;
          box-shadow: 0 5px 0 1px ($yellow - #333);
      }
  }
}