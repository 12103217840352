@import url('https://fonts.googleapis.com/css?family=Passion+One:700|Roboto:400,500,700&display=swap');

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@font-face {
  font-family: 'Absolut Headline';
  src: url('/fonts/AbsolutHeadline.eot');
  src: url('/fonts/AbsolutHeadline.eot?#iefix') format('embedded-opentype'),
      url('/fonts/AbsolutHeadline.woff2') format('woff2'),
      url('/fonts/AbsolutHeadline.woff') format('woff'),
      url('/fonts/AbsolutHeadline.ttf') format('truetype'),
      url('/fonts/AbsolutHeadline.svg#AbsolutHeadline') format('svg');
  font-weight: normal;
  font-style: normal;
}