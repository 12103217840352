.header {
    
    $h: &;

    z-index: 2;
    background: $yellow;
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5000;

    @include mq(md) {
        position: relative;
        height: 125px;
        background: url('/images/header-back.png') no-repeat center center / cover;
    }
    
    &__container {
        display: flex;
        align-items: center;
        height: 80px;
        padding: 5px 0;
        justify-content: space-between;

        @include mq(md) {
            justify-content: flex-end;
            height: 100px;
        }
    }
    
    &__logo {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        height: 160px;
        z-index: 2000;

        @include mq(md) {
            display: block;
        }

        img {
            height: 100%;
            width: auto;
        }
    }

    &__logo-mobile {
        height: 100%;

        @include mq(md) {
            display: none;
        }

        img {
            height: 100%;
            width: auto;
        }
    }

    &__nav {
        @include trans;

        position: absolute;
        width: 100%;
        top: 80px;
        height: calc(100vh - 80px);
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $yellow;
        transform: translateX(-100%);

        @include mq(md) {
            position: static;
            transform: translateX(0);
            height: auto;
            width: auto;
            background: transparent;
        }
    }
    
    &__list {

        @include mq(md) {
            display: flex;
        }
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: 20px;
            
            @include mq(md) {
                margin-bottom: 0;
            }
        }

        @include mq(md) {
            margin-left: 20px;
        }

        a{
            text-transform: uppercase;
            color: $red;
            font-weight: normal;
            text-decoration: none;
            font-size: 24px;
            font-family: $titleFont;

            @include mq(md) {
                font-size: 16px;
            }
        }
    }

    &__hamburguer {
        cursor: pointer;
        width: 50px;
        height: 50px;
        border: 4px solid $white;
        border-radius: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        @include mq(md) {
            display: none;
        }

        span {
            background-color: $white;
            border-radius: 2px;
            content: '';
            display: block;
            height: 3px;
            margin: 2px 0 2px 10px;
                        
            &:nth-child(1) {
                width: 30%;
                // animation:outT 0.6s $easing backwards;
                // animation-direction:reverse;
            }
            &:nth-child(2) {
                width: 50%;
                // margin: 7px 0;
                // animation:outM 0.6s $easing backwards;
                // animation-direction:reverse;
            }
            &:nth-child(3) {
                width: 30%;
                // animation:outBtm 0.6s $easing backwards;
                // animation-direction:reverse;
            }
        }
    }

    &.-open {
        // #{$h}__hamburguer {
        //     span:nth-child(1) {
        //         animation:inT 0.6s $easing forwards;
        //     }
        //     span:nth-child(2) {
        //         animation:inM 0.6s $easing forwards;
        //     }
        //     span:nth-child(3) {
        //         animation:inBtm 0.6s $easing forwards;
        //     }
        // }

        #{$h}__nav {
            transform: translateX(0);
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
