.footer {
    background: $yellow;
    padding-top: 3%;

    &__container {
        padding-bottom: 40px;
        
        @include mq(md) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__logo {
        display: block;
        width: 30%;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        
        @include mq(md) {
            margin-bottom: 0;
            width: 10%;
            margin-right: 0;
            margin-left: 0;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: center;

        @include mq(md) {
            justify-content: flex-end;
        }
    }

    &__legal {
        display: none;

        @include mq(md) {
            display: block;
            color: $black;
            text-decoration: none;
            font-weight: 700;
            font-size: 14px;
            display: flex;
            align-items: center;
        }
    }

    &__social {
        display: flex;
        align-items: center;
        margin-left: 20px;

        li {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            width: 50px;
            border: 5px solid $white;
            border-radius: 50%;
            margin-right: 20px;

            &.-blue {
                background: $blue;
            }

            &.-black {
                background: $darkestGray;
            }

            &.-red {
                background: $red;
            }
        }

        a {
            text-decoration: none;
        }

        i {
            color: $white;
        }
    }
}
