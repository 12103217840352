.perfil {

    &__hero {
        background: url('/images/profile-header.png') no-repeat center bottom / cover;
        // height: 60vh;
        padding-top: 100px;
        padding-bottom: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        position: relative;
        
        @include mq(md) {
            padding-top: 200px;

        }

        &__container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: transparentize($orange, .7);
            border-radius: 15px;
            padding: 0 20px;
            height: 120px;
        }

        &__wrapper {
            display: flex;
        }

        &__avatar {
            height: 115px;
            width: 115px;
            position: relative;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                width: 100%;
                height: auto;
            }
        }

        &__group {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            margin-left: 20px;
            
            @include mq(md) {
                margin-left: 40px;
            }
        }

        &__title {
            color: $red;
            margin: 0;
            text-align: right;
            font-size: 20px;

            @include mq(md) {
                font-size: 24px;
            }
        }

        &__text {
            margin: 0;
            color: $white;
            font-family: $titleFont;
            font-size: 28px;

            @include mq(md) {
                font-size: 40px;
            }
        }

        &__group-wrapper {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            align-items: center;
        }

        &__number {
            color: $white;
            font-family: $titleFont;
            font-size: 28px;

            @include mq(md) {
                font-size: 40px;
            }
        }

        &__icon {
            margin-left: 10px;


        }
    }

    &__ranking {
        background: $red;
        // background: url('/images/red-back.png') no-repeat center bottom / cover;
        padding-bottom: 100px;
        padding-top: 70px;
        margin-top: -50px;

        @include mq(md) {
            padding-top: 150px;
            min-height: 0;
            margin-top: -100px;
        }

        &__table {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 40px;
      
            @include mq(md) {
                margin-bottom: 0;
            }
        }

        &__table-heading {
            display: none;

            @include mq(md) {
                display: flex;
                justify-content: space-between;
            }
        }

        &__heading-title {
            text-align: center;
            color: $white;
            font-family: $titleFont;

            &.-index {
                width: 8%;
            }

            &.-avatar {
                width: 10%;
            }

            &.-name {
                width: 70%;
            }

            &.-score {
                width: 10%;
            }
        }
      
        &__table-title {
            text-transform: uppercase;
            font-size: 32px;
            font-family: $titleFont;
            font-weight: 100;
            margin: 0;
            margin-bottom: 20px;
            color: $white;
        }
      
        &__table-group {
            display: flex;
            justify-content: space-between;
            height: 60px;
      
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
      
        &__cell {
            position: relative;
            border-radius: 0;
            background: $yellow;

            &.-first-index{
                background: $white;
            }            
            
            @include mq(md) {
                border-radius: 5px;
            }
      
            &.-index {
                width: 10%;
                line-height: 60px;
                color: $red;
                font-family: $titleFont;
                text-align: center;
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;

                @include mq(md) {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }
            }

            &.-avatar {
                width: 12%;

                @include mq(md) {
                    width: 10%;
                }

                img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate3d(-50%, -50%, 0);
                }
            }
      
            &.-name {
                width: 65%;
                padding-left: 20px;
                
                p {
                    line-height: 60px;
                    font-family: $titleFont;            
                    text-transform: uppercase;
                    color: $red;
                    margin: 0;
                    font-size: 18px;
      
                    @include mq(md) {
                        font-size: 20px;
                    }
                }
            }
      
            &.-score {
                width: 13%;
                line-height: 60px;
                color: $red;
                text-align: center;
                font-family: $titleFont;            
                font-weight: 700;
                font-size: 20px;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
      
                @include mq(md) {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }
        }

        &__container {
            width: 100%;
            position: sticky;
            top: 38%;
        }

        &__message-image {
            position: relative;
            width: 100%;
            height: 1px;
            display: none;

            @include mq(md) {
                display: block;
            }

            img {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);

                @include mq(md) {
                    width: 100%;
                    height: auto;
                }
            }
        }

        &__message-content {
            position: relative;
            z-index: 2;
            margin-left: auto;
            margin-right: auto;
            width: 60%;
            padding-top: 40px;

            @include mq(md) {
                padding-top: 20px;
                width: 85%;
            }
        }

        &__message {
            color: $white;
            font-size: 24px;
            text-align: center;
            margin-bottom: 40px;
        }

        &__button {
            font-family: $titleFont;
            font-size: 24px;
            border-radius: 50px;
            padding: 10px 0;
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 50%;
            text-align: center;
            color: $red;
            background: $yellow;
            box-shadow: 0 3px 0 1px ($yellow - #333);
        
            @include mq(md) {
                color: $yellow;
                background: $red;
                box-shadow: 0 3px 0 1px ($red - #333);
                
            }
        }
    }

    // &__blocked {
    //     position: fixed;
    //     top: 0;
    //     left:0;
    //     bottom: 0;
    //     right: 0;
    //     background: #faab00;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     z-index: 10000;
        
    //     &__warn {
    //         text-align: center;
    //         background: $red;
    //         color: $white;
    //         font-family: $titleFont;
    //         padding: 30px;
    //         width: 90%;
    //         font-size: 18px;
    //         font-weight: 100;
    //         border-radius: 10px;
    //         position: relative;

    //         &:before {
    //             position: absolute;
    //             left: 0;
    //             right: 0;
    //             height: 0;
    //             padding-bottom: 18%;
    //             bottom: 90%;
    //             content: '';
    //             z-index: 0;
    //             background: url('/images/header-modal-1.png') no-repeat top center / cover;
    //         }

    //         p {
    //             margin: 0;
    //         }

    //         i {
    //             font-size: 28px;
    //             margin-bottom: 15px;
    //             color: $yellow;
    //         }
    //     }
    // }
}

@media (orientation: portrait) {
    // .perfil__blocked { display: flex; }
    // .perfil { display: none; }
    .perfil__hero {
        @include mq(xs) {
            padding-top: 50px;
            margin-top: -20px;
        }
    }
    
    .perfil__hero__container {
        @include mq(xs) {
            display: block;
            height: auto;
            padding: 20px 0;
        }
    }

    .perfil__hero__wrapper {
        @include mq(xs) {
            align-items: center;
            justify-content: center;
            height: 100%;

            &:first-child {
                margin-bottom: 20px;
            }
        }
    }

    .perfil__hero__avatar {
        @include mq(xs) {
            height: 80px;
            width: 80px;
        }
    }

    .perfil__hero__group {
        @include mq(xs) {
            margin-left: 20px;
        }
    }

    .perfil__hero__title {
        @include mq(xs) {
            font-size: 18px;
        }
    }

    .perfil__ranking {
        @include mq(xs) {
            margin-top: -60px;
        }
    }

    .perfil__ranking__table-title.-white {
        @include mq(xs) {
            font-size: 24px;
        }
    }

    .perfil__ranking__cell.-name {
        @include mq(xs) {
            width: 62%;
        }
    }

    .perfil__ranking__cell.-score {
        @include mq(xs) {
            width: 16%;
            font-size: 16px;
        }
    }

    .perfil__ranking__message-content {
        @include mq(xs) {
            width: 90%;
        }
    }
}

@media (orientation: landscape) {
    // .perfil__blocked { display: none; }
    // .perfil { display: block; }
}