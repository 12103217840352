.home {
    overflow: hidden;
    padding-top: 80px;
    margin-bottom: -2px;

    @include mq(md) {
        margin-top: -50px;
        padding-top: 0;
    }

    &__hero {
        background: url('/images/hero-back.jpg') no-repeat center center / cover;
        position: relative;
        padding-bottom: 120px;

        &__image {
            width: 100%;
            padding-top: 60px;
            margin-top: -40px;

            @include mq(md) {
                padding-top: 0;
                margin-top: 0;
            }
            
            img {
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 80%;
                height: auto;
                
                @include mq(md) {
                    padding-top: 50px;
                    width: auto;
                    height: 70vh;
                }
            }
        }

        &__button {
            display: block;
            color: $yellow;
            font-family: $titleFont;
            text-transform: uppercase;
            font-weight: 100;
            text-align: center;
            background: $red;
            border: none;
            max-width: 350px;
            border-radius: 50px;
            text-decoration: none;
            margin-left: auto;
            margin-right: auto;
            width: 70%;
            height: 50px;
            line-height: 50px;
            font-size: 24px;
            box-shadow: 0 5px 0 1px ($red - #333);
            position: relative;
            z-index: 50;
            
            @include mq(md) {
                box-shadow: 0 10px 0 1px ($red - #333);
                line-height: 80px;
                height: 80px;
                width: 25%;
                font-size: 40px;
            }
        }

        &__lupa {
            position: absolute;
            top: 42%;
            right: 0;
            width: 30%;

            @include mq(md) {
                width: auto;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &__red-hand {
            position: absolute;
            left: 0;
            width: 20%;
            top: 45%;

            @include mq(md) {
                top: 55%;
                width: auto;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &__scroll-arrow {
            display: none;

            @include mq(md) {
                animation:bounce 0.6s $easing infinite;
                display: block;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                z-index: 4;
            }
        }

        &__vignette {
            display: none;

            @include mq(md) {
                display: block;
                position: absolute;
                right: 50px;
                bottom: -90px;
                z-index: 4;
            }
        }
    }

    &__premios,
    &__ranking {
        position: relative;
        background: url('/images/prizes-back.png') no-repeat center top / cover;
        z-index: 3;
        padding-top: 100px;
        margin-top: -135px;
        padding-bottom: 100px;

        @include mq(md) {
            padding-bottom: 300px;
            margin-top: -10%;
            padding-top: 400px;
        }
        
        &__confetti-top {
            display: none;

            @include mq(md) {
                display: block;
                position: absolute;
                top: 0;
                width: 100%;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &__dia-del-nino {
            display: none;

            @include mq(md) {
                display: block;
                position: absolute;
                left: 50%;
                top: 385px;
                transform: translate3d(-50%, -50%, 0);
                z-index: 4;
            }
        }

        &__title {
            margin: 0;
            text-align: center;
            color: $red;
            text-transform: uppercase;
            font-family: $titleFont;
            font-weight: 100;
            font-size: 80px;

            @include mq(md) {
                font-size: 200px;
            }
        }

        &__subtitle {
            margin: 0;
            text-align: center;
            text-transform: uppercase;
            color: $white;
            font-weight: 100;
            font-family: $titleFont;
            margin-bottom: 10px;
            font-size: 30px;
            width: 90%;
            margin-left: auto;
            margin-right: auto;

            @include mq(md) {
                width: auto;
                font-size: 50px;
                margin-top: -40px;
                margin-left: 0;
                margin-right: 0;
            }
        }

        &__text {
            margin: 0;
            text-align: center;
            color: $white;
            font-size: 22px;
            margin-bottom: 20px;

            @include mq(md) {
                font-family: $titleFont;
                margin-bottom: 0;
            }
        }

        &__index {
            width: 100%;
            position: relative;
            height: 0;
            overflow: hidden;
            padding-bottom: 90%; 

            img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%, -50%, 0);
                max-width: 90%;
                max-height: 90%;
            }
        }

        &__table {
            display: none;

            @include mq(md) {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        &__table-title {
            text-transform: uppercase;
            font-size: 45px;
            text-align: center;
            font-family: $titleFont;
            font-weight: 100;
            margin: 0;
            margin-bottom: 20px;

            &.-white {
                color: $white;
            }

            &.-blue {
                color: $blue;
            }

            &.-orange {
                color: $orange;
            }
        }

        &__table-group {
            display: flex;
            justify-content: space-between;
            height: 60px;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        &__cell {
            background: $white;
            position: relative;
            border-radius: 0;
            
            @include mq(md) {
                border-radius: 5px;
            }

            &.-avatar {
                width: 17%;
    
                img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate3d(-50%, -50%, 0);
                }
            }

            &.-name {
                width: 65%;
                padding-left: 20px;
                
                p {
                    line-height: 60px;
                    font-family: $titleFont;            
                    text-transform: uppercase;
                    color: $red;
                    margin: 0;
                    font-size: 18px;

                    @include mq(md) {
                        font-size: 20px;
                    }
                }
            }

            &.-score {
                width: 15%;
                line-height: 60px;
                color: $red;
                text-align: center;
                font-family: $titleFont;            
                font-weight: 700;
                font-size: 20px;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;

                @include mq(md) {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;

                }
            }
        }

        &__buttons {

            @include mq(md) {
                display: flex;
                justify-content: center;
            }
        }

        &__button {
            text-align: center;
            text-transform: uppercase;
            font-family: $titleFont;            
            text-decoration: none;
            height: 60px;
            line-height: 60px;
            font-size: 24px;
            border: none;
            border-radius: 50px;
            margin: 0 10px 20px;
            padding: 0 30px;
            display: block;

            &.-red {
                background: $red;
                color: $yellow;
                box-shadow: 0 5px 0 1px ($red - #333);
            }

            &.-yellow {
                background: $yellow;
                color: $red;
                box-shadow: 0 5px 0 1px ($yellow - #333);
            }
        }
    }

    &__ranking {
        background: url('/images/red-back.png') no-repeat center top / cover;
        margin-top: -150px;
        padding-bottom: 200px;
        z-index: 2;
        
        @include mq(md) {
            margin-top: -250px;
            padding-bottom: 300px;
        }

        &__red-hand {
            display: none; 

            @include mq(md) {
                display: block;
                position: absolute;
                left: 0;
                bottom: 50px;
            }
        }

        &__cup {
            width: 70%;
            margin-left: auto;
            margin-bottom: 40px;

            @include mq(md) {
                position: absolute;
                right: 0;
                top: 18%;
                width: auto;
                margin-left: 0;
                margin-bottom: 0;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &__title {
            color: $yellow;
        }

        &__subtitle {
            color: $yellow;
            text-transform: uppercase;
            line-height: .01;
            margin-bottom: 40px;

            @include mq(md) {
                line-height: 1;
                font-size: 90px;
                margin-bottom: 0;    
            }

        }

        &__text {
            font-family: $titleFont;
            font-size: 18px;
            margin-bottom: 40px;
            
            @include mq(md) {
                text-transform: uppercase;
                margin-bottom: 20px;
                font-size: 30px;
            }
        }

        &__search {
            width: 100%;
            border-radius: 50px;
            background: rgba(0,0,0,.30);
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;
            padding: 10px 20px;

            @include mq(md) {
                padding: 10px 40px;
            }
        }

        &__input {
            background: transparent;
            border: none;
            outline: none;
            height: 100%;
            color: $white;
            font-family: $titleFont;
            font-size: 18px;

            @include mq(md) {
                font-size: 24px;
            }

            &::placeholder {
                color: $white;
            }
        }

        &__search-button {
            background: none;
            border: none;
            outline: none;
        }

        &__table {
            margin-bottom: 40px;
            display: block;
        }

        &__table-heading {
            display: none;

            @include mq(md) {
                display: flex;
                justify-content: space-between;
            }
        }

        &__heading-title {
            text-align: center;
            color: $white;
            font-family: $titleFont;

            &.-index {
                width: 8%;
            }

            &.-avatar {
                width: 10%;
            }

            &.-name {
                width: 70%;
            }

            &.-score {
                width: 10%;
            }
        }

        &__cell {
            background: $yellow;

            &.-first-index{
                background: $white;
            }

            &.-index {
                width: 8%;
                line-height: 60px;
                color: $red;
                font-family: $titleFont;
                text-align: center;
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;

                @include mq(md) {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }
            }

            &.-avatar {
                width: 12%;

                @include mq(md) {
                    width: 10%;
                }
            }

            &.-name {
                width: 60%;

                @include mq(md) {
                    width: 70%;
                }
            }

            &.-score {
                width: 20%;        

                @include mq(md) {
                    width: 10%;
                }
            }
        }
    }

    &__comercial {
        background: url('/images/blue-back.png') no-repeat center top / cover;
        margin-top: -420px;
        position: relative;
        padding-top: 350px;
        padding-bottom: 250px;
        
        @include mq(md) {
            padding-bottom: 450px;
            padding-top: 420px;
            margin-top: -24%;
        }

        &__mono-bottom {
            display: none;

            @include mq(md) {
                position: absolute;
                height: 25%;
                bottom: -20px;
                left: 20%;
                display: block;
            }

            img {
                height: 100%;
                width: auto;
            }
        }

        &__title {
            margin: 0;
            color: $white;
            font-family: $titleFont;
            font-weight: 100;
            text-align: center;
            line-height: .8;
            margin-bottom: 40px;
            font-size: 30px;

            @include mq(md) {
                font-size: 90px;
            }

            span {
                color: $red;
            }
        }

        &__video-container {
            position: relative;
            height: 0;
            padding-bottom: 56.5%;
            overflow: hidden;
            border: 5px solid $white;
            border-radius: 50px;
            box-shadow: 0 10px 0 5px rgba(0,0,0,.15);
            
            @include mq(md) {
                box-shadow: 0 50px 0 10px rgba(0,0,0,.15);
                border: 10px solid $white;
                border-radius: 60px;
            }

            iframe {
                position: absolute;
                z-index: 2;
            }

        }
        
        &__play-button {
            margin-top: -32px;
            height: 60px;
                        
            @include mq(md) {
                height: 80px;
            }

            img {
                height: 100%;
                position: relative;
                display: block;
                width: auto;
                margin-left: auto;
                margin-right: auto;
                z-index: 6;
            }
        }
    }
}

@keyframes bounce {
    0% {
        transform: translateX(-50%);
    } 

    50% {
        transform: translate3d(-50%, 10px, 0);
    } 
}