.header--game {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    padding: 10px 10px 0 10px;
    background: #faab00;
    
    @include mq(md) {
        position: absolute;
        padding: 30px 30px 0 30px;
        background: transparent;
    }

    &__container {
        display: flex;
        justify-content: space-between;
    }

    &__logo {
        width: 60px;

        @include mq(md) {
            width: auto;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__logo-title {
        position: fixed;
        bottom: 10px;
        right: 10px;
        height: 60px;

        @include mq(md) {
            position: absolute;
            left: 50%;
            bottom: auto;
            right: auto;
            height: 150px;
            transform: translateX(-50%);
        }

        img {
            height: 100%;
            width: auto;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: flex-end;
        
        @include mq(md) {
            flex-wrap: wrap;
            width: 25%;
        }
        
        &--login {
            display: none;

            &.-active {
                display: flex;
            }
        }
        
        &--profile {
            display: none;

            &.-active {
                display: flex;
            }
        }
    }
    
    &__group {
        background: $yellow;
        border: 3px solid $white;
        position: relative;
        padding-right: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 35px;
        width: 200px;
        border-radius: 5px;
        margin-right: 5px;
        order: 3;
        padding-left: 30px;

        @include mq(md) {
            padding-left: 50px;
            margin-right: 10px;
            height: 45px;
            border-radius: 15px;
            width: 81%;
            order: 1
        }
    }

    &__avatar {
        position: absolute;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
        width: 45px;
        height: 45px;
    
        @include mq(md) {
            width: 60px;
            height: 60px;

        }

        img {
            width: 100%;
            height: 100%;
        }

        &--mono {
            background: $blue;
            border-radius: 50px;
            border: 4px solid $white;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
        }
    }

    &__text {
        color: $white;
        font-family: $titleFont;
        text-shadow: -1px 0 $black, 0 1px $black, 1px 0 $black, 0 -1px $black;
        margin: 0;
        font-size: 14px;
        line-height: 35px;

        @include mq(md) {
            line-height: 40px;
            font-size: 18px;

        }
    }

    &__total-score {
        background: $darkestGray;
        color: $white;
        font-family: $titleFont;
        border-radius: 5px;
        padding: 0 10px;
        font-size: 12px;
        height: 20px;
        line-height: 20px;

        @include mq(md) {
            line-height: 25px;
            height: 25px;
            padding: 0 30px;
            font-size: 16px;
        }
    }

    &__hamburguer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: $yellow;
        border: 3px solid $white;
        height: 35px;
        width: 35px;
        border-radius: 5px;
        order: 4;
        cursor: pointer;

        @include mq(md) {
            border-radius: 15px;
            height: 45px;
            width: 45px;
            order: 2;
        }

        span {
            background: $white;
            margin: 2px 0;
            border-radius: 15px;
            width: 70%;
            height: 3px;

            @include mq(md) {
                height: 4px;
            }
        }
    }

    &__icon-group {
        position: relative;
        display: flex;
        justify-content: flex-end;
        background: transparentize($black, .7);
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        height: 35px;
        width: 100px;
        margin-left: 10px;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;

        &:last-child {
            margin-right: 30px;
            
            @include mq(md) {
                margin-right: 0;
            }
        }
        
        @include mq(md) {
            height: 45px;
            width: 35%;
        }

        &:after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 50%;
            background: $darkestGray;
            border-top-left-radius: 50px;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
            border-bottom-left-radius: 50px;
        }

        &--score {
            order: 1;

            @include mq(md) {
                order: 3;
            }
        }

        &--time {
            order: 2;

            @include mq(md) {
                order: 4;
            }
        }
    }

    &__icon {
        position: absolute;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        left: -8px;
        height: 43px;
        width: 43px;
    
        @include mq(md) {
            height: 54px;
            width: 54px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__icon-wrapper {
        color: $white;
        z-index: 3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 5px;
        
        @include mq(md) {
            padding-right: 10px;
        }
    }

    &__wrapper-title {
        margin: 0;
        text-align: right;
        text-transform: uppercase;
        font-family: $titleFont;
        font-size: 12px;
        line-height: .7;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
    }


    &__wrapper-number {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        text-align: right;
        font-family: $titleFont;
        font-size: 20px;

        @include mq(md) {
            font-size: 24px;
        }
        
        span {
            display: block;
        }

        p {
            margin: 0;
            font-size: 14px;
        }
    }

    &__buttons {
        position: fixed;
        display: flex;
        bottom: 10px;
        left: 10px;
        height: 30px;
        border: 3px solid $white;
        border-radius: 50px;
        overflow: hidden;

        @include mq(md) {
            bottom: 30px;
            left: auto;
            right: 30px;
        }
    }

    &__button {
        width: 30px;
        background: $yellow;
        height: 100%;
        border: none;
        position: relative;

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);

            &.-horizontal {
                height: 2px;
                width: 50%;
                background: $red;
            }

            &.-vertical {
                height: 50%;
                width: 2px;
                background: $red;
            }
        }
    }

    &.-perfil {
        .header--game__icon-group--score,
        .header--game__icon-group--time,
        .header--game__buttons,
        .header--game__logo-title {
            display: none;
        }
    }
}